import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import PDF_Icon from '../icons/PDF_Icon.svg'
import DOCX_Icon from '../icons/DOCX_Icon.svg'
import RedButton from './RedButton'
import DownloadElementWide from './DownloadElementWide'

const Container = styled.div`
position:relative;
margin:auto;
max-width:520px;
display:flex;
flex-direction:column;
justify-content:center;
`        

const DownloadList = ({data}) => (
  <Container>
    {
      data.map((item,i) => (
        <DownloadElementWide key={i} link={item.link}>{item.name}</DownloadElementWide>
      ))
    }
  </Container>
)

DownloadList.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
}


export default DownloadList