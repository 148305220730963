import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import IntroAboutUs from '../components/IntroAboutUs'
import Rememberance from '../components/Rememberance'
import Layout from '../components/Layout'
import Chronik from '../components/Chronik'
import Ehrungen from '../components/Ehrungen'
import BlogPreviewAboutUs from '../components/BlogPreviewAboutUs'
import Vorstand from '../components/Vorstand'
import Spacer from '../components/Spacer'


export const AboutUsPageTemplate = ({
  introAboutUs,
  vorstand,
  blogPreviewX,
  chronik,
  ehrungen,
  rememberance,
  blog,
}) => (
    <div>
      <Helmet title="Über Uns" defer={false}/>
      <Layout activePageIndex={1} >
        <div id="top"/>
        <Spacer height={60} heightS={50}/>
        <IntroAboutUs data={ introAboutUs }/>
        <Spacer height={75} heightS={75}/>
        <div id="vorstand"/>
        <Vorstand data={ vorstand }/>
        <Spacer height={120} heightS={90}/>
        <div id="archiv"/>
        <BlogPreviewAboutUs data = { blog } data2 = { blogPreviewX }/>
        <Spacer height={120} heightS={90}/>
        <div id="chronik"/>
        <Chronik data={ chronik } />
        <Spacer height={120} heightS={100}/>
        <div id="ehrungen"/>
        <Ehrungen data={ehrungen}/>
        <Spacer height={120} heightS={100}/>
        <div id="verstorbeneKameraden"/>
        <Rememberance data={rememberance}/>
      </Layout>
    </div>
)

AboutUsPageTemplate.propTypes = {
  introAboutUs: PropTypes.object,
  vorstand: PropTypes.object,
  blogPreviewX: PropTypes.object,
  chronik: PropTypes.object,
  ehrungen: PropTypes.object,
  rememberance: PropTypes.object,
}