import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import DownloadList from './DownloadList'
import DownloadElementWide from './DownloadElementWide'


const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;
& > div {
  display:flex;
  flex-flow: row wrap;
}
`        
const TextField = styled.div`
width:100%;
margin:0 0 30px 0;
text-align:justify;

@media(min-width:993px) {
  width:40%;
  margin:0 5% 15px 5%;
  & h2 {
    text-align:left;
    
  }
}
& > h2 {
  word-wrap:break all;
  text-align:center;
  font-family:Montserrat;
  font-weight:700;
  font-style:italic;
  font-size:24px;

  @media(min-width:601px) {
    font-size:28px;
  }
  margin:0;
}
font-family:lato;
`

const IntroAboutUs = ({data}) => (
  <Container>
    <div>
    <TextField>
      <h2>{data.founding.header}</h2>
      <ReactMarkdown>{data.founding.text}</ReactMarkdown>
    </TextField>
    <TextField>
      <h2>{data.mission.header}</h2>
      <ReactMarkdown>{data.mission.text}</ReactMarkdown>
    </TextField>
    </div>
    <DownloadList data={ data.satzung }/>
  </Container>
)

IntroAboutUs.propTypes = {
  data: PropTypes.shape({
    founding: PropTypes.shape({
      header: PropTypes.string,
      text: PropTypes.string,
    }),
    mission: PropTypes.shape({
      header: PropTypes.string,
      text: PropTypes.string,
    }),
    satzung: PropTypes.arrayOf( PropTypes.shape({
      link: PropTypes.object,
      name: PropTypes.string,
    })),
  }),
}


export default IntroAboutUs