import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DropdownArrow from '../icons/dropdownArrow.svg';
import ReactMarkdown from 'react-markdown'
import PreviewCompatibleImage from './PreviewCompatibleImage';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const { useState } = React;

const Container = styled.div`
margin-top:30px;
padding-bottom:30px;
position:relative;
width:100%;
max-width:900px;
display:flex;
flex-flow: column;
align-items:center;
@media( min-width:993px) {
    align-items:flex-start;
}
& > *:first-child {
  font-family:Montserrat;
  font-style:italic;
  font-size:20px;
  text-align:left;
}

&::after {
  content:"";
  position:absolute;
  bottom:0;
  width:100%;
  height:1px;
  background-color:var(--tgrey);
}
`

const TitleContainer = styled.div`
width:100%;
display:flex;
flex-direction:column;
align-items:center;
& > p {
    text-align:center;
}

@media(min-width:993px) {
    flex-direction:row;
    & > p {
        text-align:left;
    }
    justify-content:space-between;
}
margin-bottom:30px;
`
const Image = styled.div`

@media(min-width:993px) {
    margin-left:100px;
    margin-right:180px;
}
width:170px;
height:80px;

& > * {
    width:100%;
    height:100%;
}
`
const ContentContainer = styled.div`
width:100%;
max-width:800px;
display:flex;
flex-direction:column;
align-items:center;
margin:30px auto 30px auto;
& > div {
    width:100%;
    display:flex;
    flex-flow:column wrap;
    align-items:center;
    gap:40px;
    width:100%;

    & > * {
        display:none;
    }
    & > *:nth-child(${props => props.expanded==1?('-n+100'):('-n+4')}) {
        display:block;
    }

    @media(min-width:993px) {
        flex-flow:row wrap;
        align-items:left;
        
    }
}
@media( min-width:993px) {
    flex-direction:row;
    align-items:flex-start;
    
}



`
const ExtendButton = styled(DropdownArrow)`
margin-top:15px;
width:30px;
height:30px;
display: ${props => (props.hidden)?("none"):("block")};
transition: transform 0.4s ease;
transform:rotate(${props => props.expanded==1?('180deg'):('0deg')});
&:hover {
    cursor:pointer;
}
`

const Entry = styled.div`
font-family:lato;
color:var(--tmain);
width:90%;
max-width:160px;
& > *:first-child {
  color:var(--tblack);
  font-size:18px;
  margin:0;
}
& > div {
    margin:0;
    display:flex;
    justify-content:space-between;
    & > p {
        margin: 10px 0 0px 0;
    }

}

`

const ToggleListContainer = ({expanded,data, key}) => {
    const [isExpanded, toggleIsExpanded] = useState(expanded);

    const toggleExpansion = () => (
        toggleIsExpanded(!isExpanded)
    );

    return(
        <Container id={"ehrungen"+toString(key)}>
            <TitleContainer >
                <p>{data.title}</p>
                {
                (data.image!="" && !!data.image)?(
                <Image>
                    <PreviewCompatibleImage objectFit="contain" imageInfo={ data}/>
                </Image>
                ):(<div/>)
                
                }
            </TitleContainer>
            
            <ContentContainer expanded={isExpanded?(1):(0)}>
                <div>
                    {
                        
                        data.list.map((item, i) => 
                        <Entry key={i}>
                            
                            <p>{item.name}</p>
                            <div>
                                <p>{item.location}</p>
                                <p>{item.year}</p>
                            </div>
                        </Entry>
                        )
                    }
                </div>
                {
                    (data.list.length>4)?(
                        <ExtendButton onClick={toggleExpansion}  expanded={isExpanded?(1):(0)}/>
                    ):(
                        <ExtendButton hidden/>
                    )
                    
                }
                


            </ContentContainer>
            
        </Container>
    )
};

ToggleListContainer.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        list: PropTypes.shape({
            name: PropTypes.string,
            location: PropTypes.string,
            year: PropTypes.string,
        }),
    }),
};

export default ToggleListContainer;