import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import EhrungenToogleList from './EhrungenToogleList'

const Container = styled.div`
position:relative;
width:90%;
max-width:900px;
margin:0 auto;

& > h2 {
  font-family:Montserrat;
  font-style:italic;
  font-size:24px;
  text-align:center;
  
  @media(min-width:601px) {
    font-size:28px;
  }
}
`        

const Ehrungen = ({data}) => (
  <Container>
    <h2>{data.header}</h2>
    {
      Array.isArray(data.list) ? 
      data.list.map((item,i) =>
        <EhrungenToogleList key={i} data={{title: item.ehrungname, image:item.image, list: item.recipiantList}} extended={false}/>
      ) : <div></div>
    }
    
  </Container>
)

Ehrungen.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    list: PropTypes.arrayOf( PropTypes.shape({
      ehrungname: PropTypes.string,
      image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
      recipiantList: PropTypes.arrayOf( PropTypes.shape({
        name: PropTypes.string,
        location: PropTypes.string,
        year: PropTypes.string,
      })),
    })),
  }),
}


export default Ehrungen