import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import ChronikToggleListContainer from './ChronikToggleListContainer'
import RedButton from './RedButton'
import { useRef } from 'react'
import DownArrow from '../icons/dropdownArrow.svg'
const {convertDate} = require("../../myutil")

const Container = styled.div`
position:relative;
width:90%;
max-width:900px;
margin:0 auto;
& > h2 {
  font-family:Montserrat;
  font-style:italic;
  font-size:24px;
  text-align:center;
}
& > *:last-child {
  display:flex;
  align-items:center;
  justify-content:center;
}

@media(min-width:601px){
  & > h2 {
    font-size:28px;
  }
}
`  

const SearchBar = styled.div`
position:relative;
display:flex;
flex-flow: row wrap;
width:100%;
justify-content:center;
margin-bottom:50px;
`

const SelectContainer = styled.div`
position:relative;
width:90%;
max-width:300px;
height:30px;
margin:5px;

& select {
    width:100%;
    height:100%;
    padding:0 20px;

    border-radius:100px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);

    font-size:16px;

    appearance:none;
    border: none;
    outline: none;
}

& svg {
    width:20px;
    height:20px;
    position:absolute;
    top:5px;
    right:8px;
}
`

const InputContainer = styled.div`
position:relative;
width:90%;
max-width:300px;
height:30px;
margin:5px;

& input {
    width:100%;
    height:100%;
    padding:0 20px;

    border-radius:100px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);
    font-size:16px;
    background-color:transparent;

    border: none;
    outline: none;
}

& svg {
    position:absolute;
    top:5px;
    right:8px;
}
`

const Line = styled.div`
width:100%;
height:1px;
background-color:var(--tgrey);
`

const ButtonWrapper = styled.div`
visibility:${props => props.isShown?"visible":"hidden"};
`
const getYear = (date) => {
  if(typeof date == "string" && date.length >= 10) {
    date = convertDate(date)
    return date.substr(date.length-4,date.length)
  }
  else 
    return null
}      
const createYearLists = (list) => {
  //console.log(list)
  let yearList = []

  //group a year
  let temp = []

  for(let i =0; i<list.length; i++) {

    let year = getYear(list[i].date)
    if(temp.length<=0 || getYear(temp[0].date) == year ) {
      //console.log("createYearList if: ",list[i].date)
      temp.push(list[i])
    }
    else {
      yearList.push(temp)
      temp = []
      temp.push(list[i])
      
    }
    if(temp!=[] && i==list.length-1)
    yearList.push(temp)
      
  }
  
  //console.log(yearList)
  return yearList
}
class Chronik extends React.Component { 
  constructor(props) {
    super(props)
    this.data = props.data
    this.displayedItems = 0

    this.state = {
      currentYear: "3000",
      maxDisplayedItems: 3,
    }
    this.handleChange2 = this.handleChange2.bind(this)
    this.showMoreItems = this.showMoreItems.bind(this)
  }

  handleChange2(event) {
    this.setState({currentYear: event.target.value, maxDisplayedItems: 3});
    this.setDisplayedItems(0)
    //window.scrollTo(event.target.value, {behavior:'smooth'})
    
  }
  setDisplayedItems(temp) {
    this.displayedItems = temp
  }
  showMoreItems() {
    if(this.displayedItems==this.state.maxDisplayedItems) {
      this.setDisplayedItems(0)
      let temp = this.state.maxDisplayedItems
      this.setState({
          maxDisplayedItems: temp +3,
      })
     
    
    }
    else
        console.log("did nothing")
  }
  render() {
    return (
    <Container id={this.props.id}>
      <h2>{this.data.header}</h2>
      <SearchBar>
        <SelectContainer>
          <select value={this.state.currentMonth} onChange={this.handleChange2} >
              <option value="3000">Neuste</option>
              {
                createYearLists(this.data.eventList).map((item,i) => 
                  <option key={i} value={parseInt(getYear(item[0].date))}>{getYear(item[0].date)}</option>
                )
              }
          </select>
          <DownArrow/>
        </SelectContainer>
      </SearchBar>
      {
      createYearLists(this.data.eventList).map((item,i) => 

          ( this.displayedItems < this.state.maxDisplayedItems && 
            parseInt(getYear(item[0].date)) <= this.state.currentYear
          )?(
            
            <div key={i}>
              
              { 
              this.setDisplayedItems(this.displayedItems+1)
              }
              <ChronikToggleListContainer className={getYear(item[0].date)} 
              expanded={
                (parseInt(getYear(item[0].date)) == this.state.currentYear || (this.state.currentYear >=3000 && i<2)
                )?(true):(false)
              } 
                data = {{title: getYear(item[0].date), list: item}}/>
                
            </div>
          ):(null)
        
        
      )
      }
      <ButtonWrapper isShown={(this.state.maxDisplayedItems>=createYearLists(this.data.eventList).length)?(false):(true)}>
        <RedButton PropOnClick={this.showMoreItems} >mehr Anzeigen</RedButton>
      </ButtonWrapper>
    </Container>
    )
  }
}
Chronik.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    eventList: PropTypes.arrayOf( PropTypes.shape({
      date: PropTypes.object,
      text: PropTypes.string,
    })),
  }),
}


export default Chronik