import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { AboutUsPageTemplate } from '../templates/aboutUs-template'

const AboutUsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const blog = []
  for (let i = 0; i<data.allMarkdownRemark.nodes.length; i++) {
    if(data.allMarkdownRemark.nodes[i].frontmatter.title!='') {
      blog.push(data.allMarkdownRemark.nodes[i].frontmatter)
    }
  }

  return (
    <AboutUsPageTemplate
      introAboutUs = { frontmatter.introAboutUs }
      vorstand = { frontmatter.vorstand }
      blogPreviewX = { frontmatter.blogPreviewX }
      chronik = { frontmatter.chronik }
      ehrungen = { frontmatter.ehrungen }
      rememberance = { frontmatter.rememberance }
      blog = { blog }
    />
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  })
}

export default AboutUsPage

export const aboutUsPageQuery = graphql`
query AboutUsTemplate {
  markdownRemark(frontmatter: { templateKey: { eq: "aboutUs-page"}}) {
    frontmatter {
      
      introAboutUs {
        founding {
          header          
          text          
        }
        mission {
          header          
          text          
        }
        satzung {
          link          
          name          
        }
      }
      vorstand {
        header        
        layer {
          header          
          person {
            image
              {
              childImageSharp {
                gatsbyImageData(
                  width: 800,
                )}
              }
            
            name            
            mail            
            rolle            
          }
        }
      }
      blogPreviewX {
        header        
      }
      chronik {
        header        
        eventList {
          date          
          text          
        }
      }
      ehrungen {
        header        
        list {
          ehrungname          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
          recipiantList {
            name            
            location            
            year            
          }
        }
      }
      rememberance {
        image
          {
          childImageSharp {
            gatsbyImageData(
              width: 1920,
            )}
          }
        
        header        
        list {
          date          
          name          
        }
      }
    }
  }
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC }
    limit: 1000
  ) {
    nodes {
      frontmatter {
        title        
        date        
        content        
        imageArray {
          discription          
          image
            {
            childImageSharp {
              gatsbyImageData(
                width: 800,
              )}
            }
          
        }
        tagArray {
          tag          
        }
      }
    }
  }
  

}
`