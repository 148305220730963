import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DropdownArrow from '../icons/dropdownArrow.svg';
import ReactMarkdown from 'react-markdown'

const { useState } = React;
const {convertDate} = require("../../myutil")

const Container = styled.div`
position:relative;
width:100%;
margin-bottom:30px;
display: flex;
align-items:start;
justify-content:space-between;
gap:30px;
& > *:first-child {
  font-family:Montserrat;
  font-style:italic;
  font-size:20px;
  text-align:center;
}

&::after {
  content:"";
  position:absolute;
  bottom:0;
  width:100%;
  height:1px;
  background-color:var(--tgrey);
}
`

const TitleContainer = styled.div`
width:65px;
flex-shrink:0;
`

const ContentContainer = styled.div`
display:${props => props.expanded==1?('block'):('none')};
width:100%;
flex-shrink:2;
padding:28px 0px;
`
const ExtendButton = styled(DropdownArrow)`
margin-top:15px;
width:30px;
height:30px;
transition: transform 0.4s ease;
transform:rotate(${props => props.expanded==1?('180deg'):('0deg')});
&:hover {
    cursor:pointer;
}
flex-shrink:0;
`

const Entry = styled.div`
font-family:lato;
& > *:first-child {
  color:var(--tmain);
  
}
& > *{
    margin:6px 0 6px 0;
    font-size:16px;
}
margin: 24px 0 24px 0;
`

const ToggleListContainer = ({expanded,data}) => {
    const [isExpanded, toggleIsExpanded] = useState(expanded);

    const toggleExpansion = () => (
        toggleIsExpanded(!isExpanded)
    );

    return(
        <Container>
            <TitleContainer >
                <p>{data.title}</p>
                
            </TitleContainer>
            
            <ContentContainer expanded={isExpanded?(1):(0)}>
            {
                data.list.map((item2, i2) => 
                <Entry>
                <p>{convertDate(item2.date)}</p>
                <ReactMarkdown>{item2.text}</ReactMarkdown>
                </Entry>
                )
            }
            </ContentContainer>
            <ExtendButton onClick={toggleExpansion} expanded={isExpanded?(1):(0)}/>
        </Container>
    )
};

ToggleListContainer.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        list: PropTypes.shape({
            text: PropTypes.string,
            date: PropTypes.string,
        }),
    }),
};

export default ToggleListContainer;