import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Arrow from "../icons/arrowwithtail.svg"
import DoubleArrow from "../icons/DoubleArrow.svg"
import Lupe from "../icons/Lupe.svg"
import { render } from 'react-dom'
import RedButton from './RedButton'
import DownArrow from '../icons/dropdownArrow.svg'
const {slugify, convertDate} = require("../../myutil")

const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;

& > h2 {
    font-family:Montserrat;
    font-style:italic;
    font-size:28px;
    text-align:center;
}

& p {
    text-align:justify;
}
`
const Tags = styled.div`
position:relative;
display:flex;
flex-flow: row wrap;
align-items:center;
margin-top:15px;
& p {
    font-size: 14px;
    font-weight:400;
    padding: 6px 12px;
    background-color:#ddd;
    border-radius:100px;
    margin 4px 10px 3px 4px;
    font-family:lato;
}
`
const Line = styled.div`
position:relative;
width:100%;
height:1px;
background-color:#ddd;
margin-top:10px;
margin-bottom:20px;

`

const Blog = styled.div`
padding:0% 3%;
max-width:800px;
margin:0 auto;
font-family:lato;
& > p:first-child {
    color:var(--tmain);
    margin:0;
}
& > a {
    text-decoration:none;
    font-size:24px;
    font-weight:700;
    color:var(--tgrey);
    
}
& > div {
    position:relative;
    display:flex;
    justify-content:space-between;
}
`
const StyledAnchorLink = styled(AnchorLink)`
display:flex;
min-width:100px;
flex-direction:row;
align-items:center;
justify-content:end;
background-color: transparent;
border:none;
color: var(--tmain);
transition: 0.2s ease;
font-family: lato;
text-decoration:none;
gap:10px;
& p { 
    font-size:16px;    
}

& svg {
    & > * {
        fill:var(--tmain);    
    }
    transform:rotate(180deg);
    margin-left:10px;
    
}
&:hover {
    cursor:pointer;
    color:var(--tgrey-dark);
    & svg {
        & > * {
            fill:var(--tgrey-dark);
        }
    }
}

`
const SearchBar = styled.div`
position:relative;
display:flex;
flex-flow: row wrap;
width:100%;
justify-content:center;
margin-bottom:50px;
`

const SelectContainer = styled.div`
position:relative;
width:90%;
max-width:300px;
height:30px;
margin:5px;

& select {
    width:100%;
    height:100%;
    padding:0 20px;

    border-radius:100px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);

    font-size:16px;

    appearance:none;
    border: none;
    outline: none;
}

& svg {
    width:20px;
    height:20px;
    position:absolute;
    top:5px;
    right:8px;
}
`

const InputContainer = styled.div`
position:relative;
width:90%;
max-width:300px;
height:30px;
margin:5px;

& input {
    width:100%;
    height:100%;
    padding:0 20px;

    border-radius:100px;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);
    font-size:16px;
    background-color:transparent;

    border: none;
    outline: none;
}

& svg {
    position:absolute;
    top:5px;
    right:8px;
}
`

const ShowMoreButton = styled.button`
margin:auto;
background-color:transparent;
border:none;
cursor:pointer;
display:${props => props.isShown?"flex":"none"};
align-items:center;
gap:15px;

`
class BlogsPreviewAboutUs extends React.Component {
    constructor(props) {
        super(props)
        this.data = props.data
        this.data2 = props.data2
        this.displayedItems = 0
        this.state = {
            currentSearch: "",
            currentYear: "-1",
            maxDisplayedItems: 6,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.isInSearch = this.isInSearch.bind(this)
        this.getYears = this.getYears.bind(this)
        this.isRightYear = this.isRightYear.bind(this)
        this.setDisplayedItems = this.setDisplayedItems.bind(this)
        this.showMoreItems = this.showMoreItems.bind(this)
    }

    handleChange(event) {
        this.setState({currentSearch: event.target.value});
        this.setDisplayedItems(0)
    }
    handleChange2(event) {
        this.setState({currentYear: event.target.value});
        this.setDisplayedItems(0)
    }
    //handle wethear an item should be displayed
    
    isInSearch(item) {
        let key = this.state.currentSearch
        if(key=="")
            return true
        else
            key = key.toLowerCase()

        let tagFound = false
        if (Array.isArray(item.tagArray)) {
            //is displayed when search is part of a tag
            for (let i = 0; i<item.tagArray.length; i++) {
                if(item.tagArray[i].tag.toLowerCase().indexOf(key)!=-1)
                tagFound = true
            }
        }
        //is displayed when search is in title
        if(item.title != undefined && item.title.toLowerCase().indexOf(key)!=-1)
            tagFound = true

        return tagFound
    }
    isRightYear(item) {
        let item_year = item.substring(0,4)
        if(item_year == this.state.currentYear)
            return true
        else
            return false
    }
    setDisplayedItems(temp) {
        this.displayedItems = temp
    }
    showMoreItems() {
        if(this.displayedItems==this.state.maxDisplayedItems) {
            this.setDisplayedItems(0)
            let temp = this.state.maxDisplayedItems
            this.setState({
                maxDisplayedItems: temp +3,
            })
        }

    }
    //could update this to use dateConversion from myutil.js
    getYears(blogArray) {
        let years = []
        for (let i = 0; i<blogArray.length; i++) {
            let year = blogArray[i].date.substr(0,4)
            if(years.indexOf(year)==-1)
                years.push(year)
        }
        return years
    }
    
    render() {
        
        return (
        //handles the case that data is not passed for cms preview
        (Array.isArray(this.data))?(
        <Container>
            <h2>{this.data2.header}</h2>
            <SearchBar>
                <SelectContainer>
                    <select value={this.state.currentMonth} onChange={this.handleChange2} >
                        <option value="-1">Aktuelle</option>
                        {
                            this.getYears(this.data).map((item,i) => 
                            <option key={i} value={item}>{item}</option>
                            )
                        }
                    </select>
                    <DownArrow/>
                </SelectContainer>
                <InputContainer>
                    <input type="text" value={this.state.currentSearch} onChange={this.handleChange} />
                    <Lupe/>
                </InputContainer>
            </SearchBar>
            {
                this.data.map((item,i) => 
                
                (   
                    !!item &&
                    this.isInSearch(item) &&
                    (this.isRightYear(item.date) || this.state.currentYear=="-1") && 
                    this.displayedItems < this.state.maxDisplayedItems
                )?(
                
                <Blog key = {i}>
                    { this.setDisplayedItems(this.displayedItems+1)}
                    <p>{convertDate(item.date)}</p>
                    <AnchorLink to={slugify(item.title)}>{item.title}</AnchorLink>
                    <div>
                    <Tags>
                    {   
                        (Array.isArray(item.tagArray))?(
                            item.tagArray.map((item2, ii) => 
                            <p key={ii}>{item2.tag}</p>
                            )
                        ):(<div></div>)
                    }
                    </Tags>
                    <StyledAnchorLink to={slugify(item.title)}>
                        <Arrow></Arrow>
                        <p>weiter lesen</p>
                    </StyledAnchorLink>
                    </div>
                    <Line/>
                </Blog>
                ):(null))
        }
        <ShowMoreButton onClick={this.showMoreItems} isShown={(this.state.maxDisplayedItems>=this.data.length-2)?(false):(true)}>
            <RedButton>mehr anzeigen</RedButton>
        </ShowMoreButton>
        </Container>
        ):(
            <div>Blog Posts können in diesem Modus nicht angezeigt werden.</div>
        )
        )
    }
}
BlogsPreviewAboutUs.propTypes = {
    data: PropTypes.arrayOf( 
        PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                slug: PropTypes.string,
                date: PropTypes.string,
                content: PropTypes.string,
                tagArray: PropTypes.arrayOf( PropTypes.shape({
                    tag: PropTypes.string,
                })),
            })
    }),
    )
}

export default BlogsPreviewAboutUs