import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
//may delete these 2 if not used
import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactMarkdown from 'react-markdown'


const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:0 auto;
& > h2 {
  text-align:center;
  font-family:Montserrat;
  font-weight:700;
  font-size:24px;
}

@media(min-width:601px){
  & > h2 {
    font-size:28px;
  }
}
`        
const Liste = styled.div`
position:relative;
margin:0 auto;
width:90%;
max-width:350px;

& > div {
  display:flex;
  align-items:center;
  & > p {
    margin:0;
    margin-right:30px;
    font-family:lato;
    font-size:22px;
  }
  & > div {
    font-family:lato;
    font-size:16px;
  }
}

@media(min-width:601px){
  & > div {
    & > div {
      font-size:18px;
    }
  }
}
`
const Image = styled.div `
margin:auto;
position:relative;
width:300px;
height:300px;

& >* {
    height:100%;
    width:100%;
}
` 
const Rememberance = ({data}) => (
  <Container>
    <Image>
      <PreviewCompatibleImage imageInfo = {data}/>
    </Image>
    <h2>{data.header}</h2>
    <Liste>
    {
      data.list.map((item, i) => (
        <div key={i}>
          <p>{item.date}</p>
          <div>
            <p>{item.name}</p>
          </div>
        </div>
      ))
    }
    </Liste>
  </Container>
)

Rememberance.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
    header: PropTypes.string,
    list: PropTypes.arrayOf( PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
    })),
  }),
}


export default Rememberance