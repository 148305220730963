import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Carousel from './CarouselWithStatic'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Container = styled.div`
position:relative;
width:100%;

& > h2 {
    text-align:center;
    font-family:Montserrat;
    font-weight:700;
    font-size:24px;
}

@media(min-width:601px){
    & > h2 {
        font-size:28px;
    }
}
`

const LayerContainer = styled.div`
margin:0 auto;

& > h3 {
    text-align:center;
    font-family:Montserrat;
    font-size:18px;
    font-weight:700;
    color:var(--tgrey);
    margin:30px 0 15px 0;

    @media(min-width:601px){
        font-size:20px;
        margin:55px 0 25px 0;
    }
} 
`

const Element = styled.div`
position:relative;
width:240px;
text-align:center;
padding:0 20px;

& p {
    font-family:Lato;
    font-size:16px;
    margin:0;
}

.name {
    font-weight:700;
    margin-top:20px;
}

.mail {
    font-weight:300;
}

.rolleRed {
    font-weight:700;
    color:var(--tmain);
}

.rolleBlue {
    font-weight:700;
    color:#065F9F;
}

.rolleYellow {
    font-weight:700;
    color:#D1A711;
}

.rolleGreen {
    font-weight:700;
    color:#70AD47;
}
`

const Image = styled.div`
position:relative;
height:160px;
width:160px;
margin:0 auto;
border-radius:160px;

& > * {
  height:100%;
  width:100%;
  border-radius:160px;
}
`

const Vorstand = ({data}) => {
    return(
        <Container>
            <h2>{data.header}</h2>
            {
                data.layer.map((item,i) => (
                <LayerContainer key={i}>
                    {
                        item.header!="" && <h3>{item.header}</h3>
                    }
                    <Carousel width={240} elementCount={item.person.length}>
                        {
                            item.person.map((person,j) => (
                                <Element key={j}>
                                    <Image>
                                        <PreviewCompatibleImage imageInfo={person.image}/>
                                    </Image>
                                    <p className='name'>{person.name}</p>
                                    <p className='mail'>{person.mail}</p>
                                    {
                                        i!=1 && i!=2 && i!=3 && <p className='rolleRed'>{person.rolle}</p>
                                    }
                                    {
                                        i==1 && <p className='rolleBlue'>{person.rolle}</p>
                                    }
                                    {
                                        i==2 && <p className='rolleYellow'>{person.rolle}</p>
                                    }
                                    {
                                        i==3 && <p className='rolleGreen'>{person.rolle}</p>
                                    }
                                    
                                </Element>
                            ))
                        }
                    </Carousel>
                </LayerContainer>
                ))
            }
        </Container>
    )
};

Vorstand.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    layer: PropTypes.arrayOf( PropTypes.shape({
      header: PropTypes.string,
      person: PropTypes.arrayOf( PropTypes.shape({
        image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        name: PropTypes.string,
        mail: PropTypes.string,
        rolle: PropTypes.string,
      })),
    })),
  }),
}


export default Vorstand